import {useLayoutEffect, useRef} from 'react'
import {gsap} from 'gsap'
import {BsGithub, BsLinkedin} from 'react-icons/bs'
import {FaFileDownload} from 'react-icons/fa'
import resume from '../../utilities/resume_AndresRomero.pdf'

const LINKS = [
   {
      name: 'github',
      icon: BsGithub,
      href: 'https://github.com/andresfelipe328'
   },
   {
      name: 'linkedIn',
      icon: BsLinkedin,
      href: 'https://www.linkedin.com/in/andresrn328/'
   },
   {
      name: 'resume',
      icon: FaFileDownload,
      href: resume,
      download: true
   }
]

const ExternalLinks = () => {

   const eLinksRef = useRef(null)
   useLayoutEffect(() => {
      const eLinksChildren = eLinksRef.current.childNodes
      
      const ctx = gsap.context(() => {
         gsap.fromTo(eLinksChildren, 
            {
               opacity: 0,
               y: 15
            },
            {
               opacity: 1,
               y: 0,
               duration: .8,
               delay: .5,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )
      }, eLinksRef)

      return () => ctx.revert()
   }, [])

   return (
      <ul className='absolute lg:lg-right-center-absolute right-2 flex flex-col' ref={eLinksRef}>
         {LINKS.map((link, index) => (
            <li key={index}>
               <a className='relative group flex items-center p-2' href={link.href} target='blank' download={'Resume_AndresRomero.pdf' || false}>
                  <link.icon className='external-link group-hover:-translate-x-14 group-hover:scale-[135%] group-hover:text-extra-light'/>
                  <small className='absolute right-0 text-extra-light font-semibold scale-0 group-hover:scale-100 origin-right transition-all duration-300 ease-in-out'>{link.name}</small>
               </a>
            </li>
         ))}
      </ul>
  )
}

export default ExternalLinks
import {useLayoutEffect, useRef, useState} from 'react'
import { Helmet } from 'react-helmet-async'

import gsap from 'gsap'

import pages from '../../../utilities/aboutContents.json'

const About = () => {

   const [currPage, setCurrPage] = useState(0)

   const rootRef = useRef(null)
   const titleRef = useRef(null)
   const contentRef = useRef(null)
   const menuRef = useRef(null)
   useLayoutEffect(() => {
      contentRef.current.scrollTop = 0;

      const pageChildren = [titleRef.current, contentRef.current]
      const ctx = gsap.context(() => {
         gsap.fromTo(pageChildren, 
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               delay: .5,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )
      }, rootRef)

      return () => ctx.revert()

   }, [currPage])
   useLayoutEffect(() => {
      const pageChildren = menuRef.current.childNodes
      const ctx = gsap.context(() => {
         gsap.fromTo(pageChildren,
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               delay: 1.05,
               stagger: .1,
               ease: "elastic.out(1, 0.75)",
            }  
         )
      }, rootRef)

      return () => ctx.revert()

   }, [])
   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Andres Romero - About</title>
         </Helmet>
      
         <div className='flex flex-1 flex-col gap-1 items-center justify-center' ref={rootRef}>

            <h1 className='mb-2 font-bold uppercase text-lg text-animate text-extra-light' ref={titleRef}>
               {pages[currPage].title}
            </h1>
            <p className='w-[80%] max-h-[475px] hyphens-auto overflow-auto text-main-light p-4' ref={contentRef}>
               {pages[currPage].content}
            </p>


            <ul className='absolute bottom-12 flex items-center gap-10' ref={menuRef}>
               {pages.map((page, index) => (
                  <li key={index}>
                     <button
                     onClick={() => setCurrPage(index)} 
                     className={`button text-sm px-4 py-1 hover:bg-extra-light hover:text-main-dark ${currPage === index ? 'bg-extra-light text-main-dark' : 'text-main-light'}`}
                     >
                        {page.title}
                        <div className={`horizontal ${currPage === index && 'scale-x-0'}`}></div>
                        <div className={`vertical ${currPage === index && 'scale-y-0'}`}></div>
                     </button>
                  </li>
               ))}
            </ul>
         </div>
      </>
   )
}

export default About
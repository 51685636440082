import {useLayoutEffect, useRef} from 'react'

import gsap from 'gsap'

import softSkills from '../../../assets/softskills.svg'
import { SOFTSKILLS } from '../../../utilities/skills'

const SoftSkills = () => {
   const rootRef = useRef(null)
   const titleRef = useRef(null)
   const softSkillsRef = useRef(null)
   useLayoutEffect(() => {
      const titleChildren = titleRef.current.childNodes
      const softSkillsChildren = softSkillsRef.current.childNodes

      const ctx = gsap.context(() => {
         gsap.fromTo(titleChildren, 
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               delay: .5,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )
         gsap.fromTo(softSkillsChildren,
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               delay: .95,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )  
      }, rootRef)

      return () => ctx.revert()
   }, [])


   return (
      <div ref={rootRef}>
         <div className='flex gap-2 items-end' ref={titleRef}>
            <img src={softSkills} alt="monitor-hard skills" className='w-16 h-16'/>
            <h1 className='mb-2 font-bold uppercase text-lg text-animate'> Soft Skills</h1>
         </div>

         <ul className='flex flex-col gap-3' ref={softSkillsRef}>
            {SOFTSKILLS.map((skill, index) => (
               <li key={index} className="flex gap-4 items-center text-main-light">
                  <img src={skill.icon} alt="attention logo" className='w-10 h-10'/>
                  {skill.name}
               </li>
            ))}
         </ul>
      </div>
   )
}

export default SoftSkills
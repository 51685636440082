import {useLayoutEffect, useRef} from 'react' 
import { Route, Routes } from "react-router-dom";
import {gsap} from 'gsap'
// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";

import Navbar from "./components/global/Navbar";
import About from "./components/pages/about/About";
import Home from "./components/pages/home/Home";
import ExternalLinks from "./components/global/ExternalLinks";
import Skills from './components/pages/skills/Skills';
import Projects from './components/pages/projects/Projects';


const App = () => {
  // const particlesInit = async (main) => {
  //   console.log(main);
  //   await loadFull(main);
  // };

  // const particlesLoaded = (container) => {
  //   console.log(container);
  // };

  const rootRef = useRef(null)
  const bodyRef = useRef(null)
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(bodyRef.current, 
        {
          opacity: 0
        },
        {
          opacity: 1,
          duration: 1
        }
      )
    }, rootRef)

    return () => ctx.revert()
  }, [])

  return (

    <main className="bg-main-bg relative" ref={rootRef}>
      {/* <div className='partial-bg absolute top-0 left-0 w-screen h-screen'>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 60,
            interactivity: {
              events: {
                onClick: {
                  enable: false,
                },
                onHover: {
                  enable: false,
                },
                resize: true
              }
            },
            particles: {
              color: {
                value: "#f5cb5c"
              },
              links: {
                enable: false,
              },
              collisions: {
                enable: true
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce"
                },
                random: false,
                speed: 1,
                straight: false
              },
              number: {
                density: {
                  enable: true,
                  area: 650
                },
                value: 250
              },
              opacity: {
                value: 0.5,
                random: true
              },
              size: {
                value: { min: 1, max: 5 }
              }
            },
            detectRetina: true
          }}

        />
      </div> */}

        <div className="relative min-h-[844px] h-screen max-w-7xl mx-auto flex p-4" ref={bodyRef}>
          <Navbar/>

          <Routes>
            <Route path='' element={<Home/>}/>
            <Route path='about' element={<About/>}/>
            <Route path='skills' element={<Skills/>}/>
            <Route path='projects' element={<Projects/>}/>
          </Routes>

          <ExternalLinks/>
        </div>
    </main>
  );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path='*' element={<App/>}/>
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
  </React.StrictMode>
);
import {useEffect, forwardRef, useCallback} from 'react'

const RotateElem = forwardRef(({children}, ref) => {

  const rotateElement = (e, elem) => {
    const x = e.clientX
    const y = e.clientY
    const middleX = window.innerWidth / 2
    const middleY = window.innerHeight / 2
    const offsetX = ((x - middleX) / middleX) * 20
    const offsetY = ((y - middleY) / middleY) * 20

    elem.style.setProperty('--rotateX', -1 * offsetY + 'deg')
    elem.style.setProperty('--rotateY', offsetX + 'deg')
  }

  const handleRotate = useCallback(() => {
    const elem = ref.current
    
    window.addEventListener('mousemove', (e) => {
       rotateElement(e, elem)
    })
  }, [ref])

  useEffect(() => {
    handleRotate()

    return () => {
      window.removeEventListener('mousemove', rotateElement)
    }

 }, [handleRotate])

  return (
    <div className='absolute z-10' ref={ref}>
      {children}
    </div>
  )
})

export default RotateElem
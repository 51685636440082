import { useState, useLayoutEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'

import gsap from 'gsap'

import HardSkills from './HardSkills'
import SoftSkills from './SoftSkills'

const MENU = [
   {
      type: 'hard',
      name: 'Hard Skills'
   }, 
   {
      type: 'soft',
      name: 'Soft Skills'
   }
]

const Skills = () => {

   const [currSkill, setCurrSkill] = useState('hard')
   const rootRef = useRef(null)
   const menuRef = useRef(null)
   useLayoutEffect(() => {
      const menuChildren = menuRef.current.childNodes

      const ctx = gsap.context(() => {
         gsap.fromTo(menuChildren, 
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               delay: 2.25,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )
      }, rootRef)

      return () => ctx.revert()

   }, [])

   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Andres Romero - Skills</title>
         </Helmet>

         <div className='relative flex flex-1 flex-col gap-1 items-center justify-center' ref={rootRef}>
            {currSkill === 'hard' ? <HardSkills/> : <SoftSkills/>}

            <ul className='absolute bottom-7 flex items-center gap-10' ref={menuRef}>
               {MENU.map((btn, index) => (
                  <li key={index}>
                     <button
                     onClick={() => setCurrSkill(btn.type)} 
                     className={`button text-sm px-4 py-1 hover:bg-extra-light hover:text-main-dark ${currSkill === btn.type ? 'bg-extra-light text-main-dark' : 'text-main-light'}`}
                     >
                        {btn.name}
                        <div className={`horizontal ${currSkill === btn.type && 'scale-x-0'}`}></div>
                        <div className={`vertical ${currSkill === btn.type && 'scale-y-0'}`}></div>
                     </button>
                  </li>
               ))}
            </ul>
         </div>
      </>
   )
}

export default Skills
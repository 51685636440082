import {useLayoutEffect, useRef} from 'react'

import gsap from 'gsap'
import SplitType from 'split-type'
import { Helmet } from 'react-helmet-async'


const Home = () => {

   const rootRef = useRef(null)
   const h1TextRef = useRef(null)
   const nameRef = useRef(null)
   const smallTextRef = useRef(null)
   useLayoutEffect(() => {
      const h1Text = SplitType.create(h1TextRef.current)
      const name = SplitType.create(nameRef.current)

      const ctx = gsap.context(() => {
         gsap.fromTo(h1Text.chars, 
            {
               opacity: 0,
               y: -15
            }, 
            {
               opacity: 1,
               y: 0,
               delay: .5,
               stagger: .1,
               ease: "elastic.out(1, 0.75)",
            }  
         )
         
         gsap.fromTo(name.words, 
            {  
               opacity: 0,
               y: -15
            },
            {
               y: 0,
               opacity: 1,
               delay: 2,
               stagger: .5,
               ease: "elastic.out(1, 0.75)",
            }
         )
   
         gsap.fromTo(smallTextRef.current, 
            {
               opacity: 0,
               y: 15
            },
            {
               opacity: 1,
               y: 0,
               delay: 3,
               ease: "elastic.out(1, 0.75)"
            }
         )
      }, rootRef)

      return () => ctx.revert()
      
   }, [])


   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Andres Romero - Home</title>
         </Helmet>

         <div className='flex flex-1 flex-col gap-1 items-center justify-center mt-24' ref={rootRef}>

            <div className='flex flex-col items-center sm:flex-row sm:items-end gap-2'>
               <h1 className='font-bold uppercase text-base sm:text-xl text-animate' ref={h1TextRef}>
                  Welcome, I am 
               </h1>
               <span className='text-extra-light font-bold text-[1.625rem] leading-7 sm:text-3xl text-shadow' ref={nameRef}>
                  Andres Romero
               </span> 
            </div>

            <small className='text-main-light/75' ref={smallTextRef}>learn more about me, my skills, and my work.</small>
            
         </div>
      </>
   )
}

export default Home
import {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {gsap} from 'gsap'

import LOGO from '../../assets/logo.svg'
import RotateElem from '../animations/RotateElem'

const PAGES = [
   {
      page: 'about',
      href: '/about'
   },
   {
      page: 'skills',
      href: '/skills'
   },
   {
      page: 'projects',
      href: '/projects'
   }
]

const Navbar = () => {
   const [currPage, setCurrPage] = useState('')
   const location = useLocation()
   useEffect(() => {
      const page = location.pathname
      setCurrPage(page.substring(page.indexOf('/') + 1))
   },[location])

   const rootRef = useRef(null)
   const logoRef = useRef(null)
   const menuRef = useRef(null)
   useLayoutEffect(() => {
      const menuChildren = menuRef.current.childNodes

      const ctx = gsap.context(() => {
         gsap.fromTo(menuChildren, 
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               duration: .8,
               delay: .5,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )

         gsap.fromTo(logoRef.current, 
         {
            opacity: 0,
         },
         {
            opacity: 1,
            duration: .8,
            delay: .5,
            ease: "elastic.out(1, 0.75)"
         }  
      )
      }, rootRef)

      return () => ctx.revert()
      
   }, [])

   return (
      <div ref={rootRef}>
         <RotateElem ref={logoRef}>
            <Link to=''>
               <div className='outline-logo rotate-elem'>
                  <img src={LOGO} alt="logo" className='w-20 h-20'/>
               </div>
            </Link>
         </RotateElem>

         <ul className='absolute lg:lg-left-center-absolute md-center-absolute flex gap-4 z-20' ref={menuRef}>
            {PAGES.map((link, index) => ((
               <Link to={link.href} key={index}>
                  <div className={`page-link ${currPage === link.page && 'current-page'}`}></div>
               </Link>
            )))}
         </ul>
      </div>
   )
}

export default Navbar
import {useLayoutEffect, useRef} from 'react'

import gsap from 'gsap'

import monitor from '../../../assets/monitor.svg'
import { HSKILLONE, HSKILLTWO, HSKILLTHREE, HSKILLFOUR, HSKILLFIVE, HSKILLSIX } from '../../../utilities/skills'

const HardSkills = () => {
   const rootRef = useRef(null)
   const titleRef = useRef(null)
   const skillGOne = useRef(null)
   const skillGTwo = useRef(null)
   const skillGThree = useRef(null)
   const skillGFour = useRef(null)
   const skillGFive = useRef(null)
   const skillGSix = useRef(null)
   useLayoutEffect(() => {
      const titleChildren = titleRef.current.childNodes
      const skills = [skillGOne.current, skillGTwo.current, skillGThree.current, skillGFour.current, skillGFive.current, skillGSix.current]

      const ctx = gsap.context(() => {
         gsap.fromTo(titleChildren, 
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               delay: .5,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )
         gsap.fromTo(skills,
            {
               opacity: 0,
               y: -15
            },
            {
               opacity: 1,
               y: 0,
               delay: .95,
               stagger: .2,
               ease: "elastic.out(1, 0.75)"
            }
         )  
      }, rootRef)

      return () => ctx.revert()
   }, [])

   return (
      <div ref={rootRef}>
         <div className='flex gap-2 items-end' ref={titleRef}>
            <img src={monitor} alt="monitor-hard skills" className='w-16 h-16'/>
            <h1 className='mb-2 font-bold uppercase text-lg text-animate'> Hard Skills</h1>
         </div>

         <div className='flex flex-col gap-1'>
            <ul className="flex flex-col" ref={skillGOne}>
               <h3 className='text-yellow-300'>Programming Languages:</h3>
               {HSKILLONE.map((skill, index) => (
                  <li key={index} className="flex gap-2 items-center text-main-light">
                     <skill.icon className="text-yellow-300"/>
                     {skill.name}
                  </li>
               ))}
               
            </ul>

            <div className="flex gap-4">
               <ul className="flex flex-col" ref={skillGTwo}>
                  <h3 className='text-yellow-300'>Frameworks:</h3>
                  {HSKILLTWO.map((skill, index) => (
                  <li key={index} className="flex gap-2 items-center text-main-light">
                     <skill.icon className="text-yellow-300"/>
                     {skill.name}
                  </li>
               ))}
               </ul>

               <ul className="flex flex-col" ref={skillGThree}>
                  <h3 className='text-yellow-300'>Front-end:</h3>
                  {HSKILLTHREE.map((skill, index) => (
                  <li key={index} className="flex gap-2 items-center text-main-light">
                     <skill.icon className="text-yellow-300"/>
                     {skill.name}
                  </li>
               ))}
               </ul>
            </div>

            <div className="flex gap-4">
               <ul className="flex flex-col" ref={skillGFour}>
                  <h3 className='text-yellow-300'>DBM Systems:</h3>
                  {HSKILLFOUR.map((skill, index) => (
                  <li key={index} className="flex gap-2 items-center text-main-light">
                     <skill.icon className="text-yellow-300"/>
                     {skill.name}
                  </li>
               ))}
               </ul>
               
               <ul className="flex flex-col" ref={skillGFive}>
                  <h3 className='text-yellow-300'>Management:</h3>
                  {HSKILLFIVE.map((skill, index) => (
                  <li key={index} className="flex gap-2 items-center text-main-light">
                     <skill.icon className="text-yellow-300"/>
                     {skill.name}
                  </li>
               ))}
               </ul>
            </div>

            <ul className="flex flex-col" ref={skillGSix}>
               <h3 className='text-yellow-300'>Extra:</h3>
               {HSKILLSIX.map((skill, index) => (
                  <li key={index} className="flex gap-2 items-center text-main-light">
                     <skill.icon className="text-yellow-300"/>
                     {skill.name}
                  </li>
               ))}
            </ul>
         </div>
      </div>
   )
}

export default HardSkills
import { useLayoutEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

import gsap from "gsap";

import projects from "../../../utilities/projects.json";
import { AiOutlineLink, AiFillGithub } from "react-icons/ai";

const Projects = () => {
  const [currProject, setCurrProject] = useState(0);

  const rootRef = useRef(null);
  const titleRef = useRef(null);
  const headingRef = useRef(null);
  const descriptionRef = useRef(null);
  const linkRef = useRef(null);
  const pagesRef = useRef(null);
  useLayoutEffect(() => {
    const headingChildren = headingRef.current.childNodes;
    const linkChildren = linkRef.current.childNodes;
    const ctx = gsap.context(() => {
      gsap.fromTo(
        titleRef.current,
        {
          opacity: 0,
          y: -15,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          ease: "elastic.out(1, 0.75)",
        }
      );

      gsap.fromTo(
        headingChildren,
        {
          opacity: 0,
          x: -15,
        },
        {
          opacity: 1,
          x: 0,
          delay: 0.75,
          stagger: 0.1,
          ease: "elastic.out(1, 0.75)",
        }
      );

      gsap.fromTo(
        descriptionRef.current,
        {
          opacity: 0,
          x: 15,
        },
        {
          opacity: 1,
          x: 0,
          delay: 0.75,
          ease: "elastic.out(1, 0.75)",
        }
      );

      gsap.fromTo(
        linkChildren,
        {
          opacity: 0,
          y: 15,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.95,
          stagger: 0.1,
          ease: "elastic.out(1, 0.75)",
        }
      );
    }, rootRef);

    return () => ctx.revert();
  }, [currProject]);
  useLayoutEffect(() => {
    const pageChildren = pagesRef.current.childNodes;
    const ctx = gsap.context(() => {
      gsap.fromTo(
        pageChildren,
        {
          opacity: 0,
          y: -15,
        },
        {
          opacity: 1,
          y: 0,
          delay: 1.25,
          stagger: 0.1,
          ease: "elastic.out(1, 0.75)",
        }
      );
    }, rootRef);

    return () => ctx.revert();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Andres Romero - Projects</title>
      </Helmet>

      <div
        className="relative flex flex-col flex-1 gap-10 items-center justify-center"
        ref={rootRef}
      >
        <div className="flex flex-col gap-4">
          <h1
            ref={titleRef}
            className="py-2 px-4 rounded-md text-main-dark bg-extra-light border-2 border-main-dark uppercase font-bold"
          >
            {projects[currProject].title}
          </h1>

          <div className="flex flex-col gap-2 sm:gap-5 sm:flex-row">
            <div
              className="flex  sm:flex-col items-start justify-around gap-2"
              ref={headingRef}
            >
              <div className="p-4 rounded-md bg-main-dark border-2 border-extra-light">
                <img
                  src={projects[currProject].logo}
                  alt="app logo"
                  className="w-20 h-20 sm:w-28 sm:h-28"
                />
              </div>
              <ul>
                <h2 className="text-extra-light">Technologies</h2>
                {projects[currProject].technologies.map((tech, index) => (
                  <li key={index} className="text-main-light">
                    {tech}
                  </li>
                ))}
              </ul>
            </div>

            <p
              className="ml-auto w-80 text-main-light hyphens-auto"
              ref={descriptionRef}
            >
              {projects[currProject].description}
            </p>
          </div>

          <ul className="flex gap-2 justify-center" ref={linkRef}>
            <li>
              <a
                href={projects[currProject].links.web.href}
                target={
                  projects[currProject].links.web.enable === "true"
                    ? "blank"
                    : null
                }
                style={
                  projects[currProject].links.web.enable === "true"
                    ? { pointerEvents: true }
                    : { pointerEvents: false }
                }
              >
                <div
                  className={`group project-link ${
                    projects[currProject].links.web.enable === "false"
                      ? "opacity-50"
                      : "opacity-100"
                  }`}
                >
                  <AiOutlineLink className="text-xl group-hover:text-extra-light" />
                </div>
              </a>
            </li>
            <li className="group ">
              <a
                href={projects[currProject].links.github.href}
                target={
                  projects[currProject].links.github.enable === "true"
                    ? "blank"
                    : null
                }
                style={
                  projects[currProject].links.github.enable === "true"
                    ? { pointerEvents: true }
                    : { pointerEvents: false }
                }
              >
                <div
                  className={`group project-link ${
                    projects[currProject].links.github.enable === "false"
                      ? "opacity-50"
                      : "opacity-100"
                  }`}
                >
                  <AiFillGithub className="text-xl group-hover:text-extra-light" />
                </div>
              </a>
            </li>
          </ul>
        </div>

        <ul
          className="absolute bottom-10 flex gap-2 md:gap-4 items-center z-10"
          ref={pagesRef}
        >
          {projects.map((project, index) => (
            <li key={index}>
              <button
                onClick={() => setCurrProject(index)}
                className={`project-page ${
                  currProject === index && "curr-project"
                }`}
              >
                <img
                  src={project.logo}
                  alt={project.title}
                  className="w-10 h-10"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Projects;
